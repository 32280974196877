@tailwind base;
@tailwind components;

.primary-button {
  @apply bg-teal-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded
}

.tag {
  @apply inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2
}

/* 
It’s important to place this above additional CSS code right after the @tailwind components; 
statement and before the @tailwind utilities; statement. 
*/
@tailwind utilities;